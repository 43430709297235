import { CarriersSearch } from '@agentero/models';

import { searchQueryStringKey } from 'modules/agencies/agenciesSearchContext/useInitialSearch/useGetInitialFilters';

export const pageQueryStringKey = 'page';

export const getCarriersQueryString = (search: CarriersSearch) => {
	const { filters } = search;
	const queryString = new URLSearchParams();

	if (!!filters.searchTerm) {
		queryString.append(searchQueryStringKey, filters.searchTerm);
	}

	if (!!filters.appointmentStatus) {
		queryString.append('appointmentstatus', filters.appointmentStatus);
	}

	if (!!filters.isAppointable) {
		queryString.append('isAppointable', filters.isAppointable);
	}

	if (!!filters.isBindWithAgentero) {
		queryString.append('isBindWithAgentero', filters.isBindWithAgentero);
	}

	if (filters.isInstantAppointment !== undefined) {
		queryString.append('isInstantAppointment', filters.isInstantAppointment);
	}

	if (filters.isTransferable !== undefined) {
		queryString.append('isTransferable', filters.isTransferable);
	}

	if (!!filters.lineOfInsurance) {
		queryString.append('lineOfInsurance', filters.lineOfInsurance);
	}

	return !!queryString.toString() ? `?${queryString.toString()}` : '';
};
