import { AgenciesFilters } from '@agentero/models/agency';

export const statusQueryStringKey = 'status';
export const searchQueryStringKey = 'search';

export const useGetInitialFilters = (queryString: URLSearchParams): AgenciesFilters => {
	const searchTerm = useGetAgenciesSearchTerm(queryString);

	return {
		searchTerm
	};
};

const useGetAgenciesSearchTerm = (queryString: URLSearchParams) => {
	return queryString.get(searchQueryStringKey) || '';
};
