import { ORDER_DIRECTION } from '@agentero/components';
import { AgenciesOrderBy, AgenciesOrderByField } from '@agentero/models/agency';

export const orderByDirectionQueryStringKey = 'direction';
export const orderByQueryStringKey = 'orderBy';

export const useGetInitialOrder = (queryString: URLSearchParams) => {
	const initialOrderDirection = queryString.get(orderByDirectionQueryStringKey);
	const initialOrderBy = queryString.get(orderByQueryStringKey);

	const existInitialOrderDirection = Object.values(ORDER_DIRECTION).find(
		direction => direction === initialOrderDirection
	);

	const existInitialOrderBy = Object.values(AgenciesOrderByField).find(
		field => field === initialOrderBy
	);

	let initialOrder: AgenciesOrderBy = {
		direction: ORDER_DIRECTION.DESC,
		field: AgenciesOrderByField.Created
	};
	if (existInitialOrderDirection && existInitialOrderBy) {
		initialOrder = {
			direction: initialOrderDirection as ORDER_DIRECTION,
			field: initialOrderBy as AgenciesOrderByField
		};
	}

	return initialOrder;
};
