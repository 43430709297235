import { GetAgenciesRequest } from '@agentero/grpc-clients/grpc/agency-fe';

export enum AgenciesOrderByField {
	Name = 'name',
	Email = 'email',
	Created = 'createdDate',
	EOUpdatedAt = 'eoUpdatedAt'
}

export const reverseAgenciesOrderByFieldLiterals: {
	[key: string]: GetAgenciesRequest.OrderBy.OrderByField;
} = {
	[AgenciesOrderByField.Name]: GetAgenciesRequest.OrderBy.OrderByField.NAME,
	[AgenciesOrderByField.Email]: GetAgenciesRequest.OrderBy.OrderByField.EMAIL,
	[AgenciesOrderByField.Created]: GetAgenciesRequest.OrderBy.OrderByField.CREATED_AT,
	[AgenciesOrderByField.EOUpdatedAt]: GetAgenciesRequest.OrderBy.OrderByField.EO_UPDATED_AT
};

export const parseAgenciesOrderByFieldToProto = (type: AgenciesOrderByField) => {
	return reverseAgenciesOrderByFieldLiterals[type];
};
