'use client';

import { useCallback, useSyncExternalStore } from 'react';

import { notifyManager } from '@tanstack/query-core';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

export const useAgenteroCacheQuery = <T = unknown>(queryKey: QueryKey): T | undefined => {
	const client = useQueryClient();
	const queryCache = client.getQueryCache();

	return useSyncExternalStore(
		useCallback(
			onStoreChange => queryCache.subscribe(notifyManager.batchCalls(onStoreChange)),
			[queryCache]
		),
		() => client.getQueryData(queryKey),
		() => client.getQueryData(queryKey)
	);
};
