import { FetchQueryOptions, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';

import { GrpcRequest, useAgenteroNoSuspenseQuery, useAgenteroQuery } from '@agentero/query';

import { queryClient } from '../QueryCache';
import { useAgentResource } from './back-ag/useAgentResource';

export type GrpcPortalRequest<TRequest, TResponse> = {
	request: (requestData: TRequest & AgenteroUserData) => Promise<TResponse>;
	key: (requestData: TRequest & AgenteroUserData) => (string | number | object)[];
	useBackAgToken?: boolean;
};

export type GrpcPartialRequest<TRequest, TResponse> = {
	request: (requestData: TRequest & Partial<AgenteroUserData>) => Promise<TResponse>;
	key: (requestData: TRequest & Partial<AgenteroUserData>) => (string | number | object)[];
};

export type AgenteroUserData = {
	token: string;
	userId: string;
	headers?: Record<string, string>;
};

export type FetchAgQueryOptions<TResponse> = Omit<
	FetchQueryOptions<unknown, unknown, TResponse>,
	'queryFn' | 'queryKey' | 'initialData'
>;

export type UseAgQueryOptions<TResponse> = Omit<
	UseSuspenseQueryOptions<TResponse, unknown, TResponse>,
	'queryFn' | 'queryKey' | 'initialData'
>;

export type UseAgNoSuspenseQueryOptions<TResponse> = Omit<
	UseQueryOptions<TResponse, unknown, TResponse>,
	'queryFn' | 'queryKey' | 'initialData'
>;

export const useAgNoSuspenseQuery = <TRequest, TResponse>(
	queryConfig: GrpcPortalRequest<TRequest, TResponse>,
	request?: TRequest,
	reactQueryConfig?: Omit<UseAgNoSuspenseQueryOptions<TResponse>, 'suspense' | 'useErrorBoundary'>
) => {
	const {
		data: { token, id: userId }
	} = useAgentResource();

	const config: GrpcRequest<TRequest, TResponse> = {
		request: (requestData?: TRequest) => queryConfig.request({ token, userId, ...requestData }),
		key: (requestData?: TRequest) => queryConfig.key({ token, userId, ...requestData })
	};

	return useAgenteroNoSuspenseQuery(config, request, reactQueryConfig);
};

export const useAgQuery = <TRequest, TResponse>(
	queryConfig: GrpcPortalRequest<TRequest, TResponse>,
	request?: TRequest,
	reactQueryConfig?: UseAgQueryOptions<TResponse>
) => {
	const {
		data: { token, id: userId }
	} = useAgentResource();

	const config: GrpcRequest<TRequest, TResponse> = {
		request: (requestData?: TRequest) => queryConfig.request({ token, userId, ...requestData }),
		key: (requestData?: TRequest) => queryConfig.key({ token, userId, ...requestData })
	};

	return useAgenteroQuery(config, request, reactQueryConfig);
};

export const fetchAgQuery = <TRequest, TResponse>(
	queryConfig: GrpcPartialRequest<TRequest, TResponse> | GrpcPortalRequest<TRequest, TResponse>,
	requestData: TRequest & AgenteroUserData,
	config?: FetchAgQueryOptions<TResponse>
) =>
	queryClient.fetchQuery({
		queryKey: queryConfig.key(requestData),
		queryFn: () => queryConfig.request(requestData),
		staleTime: Infinity,
		retry: 1,
		...config
	});

export const prefetchAgQuery = <TRequest, TResponse>(
	queryConfig: GrpcPortalRequest<TRequest, TResponse>,
	requestData: TRequest & AgenteroUserData,
	config?: FetchAgQueryOptions<TResponse>
) =>
	queryClient.prefetchQuery({
		queryKey: queryConfig.key(requestData),
		queryFn: () => queryConfig.request(requestData),
		staleTime: Infinity,
		retry: 1,
		...config
	});
