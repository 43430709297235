import { AgenciesSearch } from '@agentero/models/agency';

import { searchQueryStringKey } from 'modules/agencies/agenciesSearchContext/useInitialSearch/useGetInitialFilters';
import {
	orderByDirectionQueryStringKey,
	orderByQueryStringKey
} from 'modules/agencies/agenciesSearchContext/useInitialSearch/useGetInitialOrder';

export const pageQueryStringKey = 'page';

export const getAgenciesQueryString = (search: AgenciesSearch) => {
	const { order, filters } = search;
	const queryString = new URLSearchParams();

	if (!!filters.searchTerm) {
		queryString.append(searchQueryStringKey, filters.searchTerm);
	}

	if (order) {
		queryString.append(orderByDirectionQueryStringKey, order.direction);
		queryString.append(orderByQueryStringKey, order.field);
	}

	queryString.append(pageQueryStringKey, search.page.toString());

	return !!queryString.toString() ? `?${queryString.toString()}` : '';
};
