import { AgentsFilters } from '@agentero/models/agent';

export const statusQueryStringKey = 'status';
export const searchQueryStringKey = 'search';

export const useGetInitialFilters = (queryString: URLSearchParams): AgentsFilters => {
	const searchTerm = useGetPolicySearchTerm(queryString);

	return {
		searchTerm
	};
};

const useGetPolicySearchTerm = (queryString: URLSearchParams) => {
	return queryString.get(searchQueryStringKey) || '';
};
