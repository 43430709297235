import { UseQueryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';

export type GrpcRequest<TRequest extends {} = {}, TResponse extends {} = {}> = {
	request: (requestData: TRequest) => Promise<TResponse>;
	key: (requestData: TRequest) => (string | number | object)[];
};

export type UseAgenteroQueryOptions<TResponse> = Omit<
	UseQueryOptions<TResponse, unknown, TResponse>,
	'queryFn' | 'queryKey' | 'initialData'
>;

export const useAgenteroNoSuspenseQuery = <TRequest extends {} = {}, TResponse extends {} = {}>(
	queryConfig: GrpcRequest<TRequest, TResponse>,
	requestData: TRequest,
	reactQueryConfig?: UseAgenteroQueryOptions<TResponse>
) => {
	return useQuery({
		queryKey: queryConfig.key(requestData),
		queryFn: () => queryConfig.request(requestData),
		...reactQueryConfig
	});
};

export const useAgenteroQuery = <TRequest extends {} = {}, TResponse extends {} = {}>(
	queryConfig: GrpcRequest<TRequest, TResponse>,
	requestData: TRequest,
	reactQueryConfig?: UseAgenteroQueryOptions<TResponse>
) => {
	return useSuspenseQuery({
		...reactQueryConfig,
		queryKey: queryConfig.key(requestData),
		queryFn: () => queryConfig.request(requestData)
	});
};
