import { PropsWithChildren, createContext, useContext } from 'react';

import { RequestError } from '@agentero/service-errors';

const GetInitialPropsErrorContext = createContext<{ error?: RequestError }>({});

type GetInitialPropsErrorProviderProps = {
	error: RequestError;
};

export const GetInitialPropsErrorProvider = ({
	children,
	error
}: PropsWithChildren<GetInitialPropsErrorProviderProps>) => (
	<GetInitialPropsErrorContext.Provider value={{ error }}>
		{children}
	</GetInitialPropsErrorContext.Provider>
);

export const useGetInitialPropsError = () => useContext(GetInitialPropsErrorContext);
