import { handleFetchCatchError, handleFetchResponse } from '@agentero/service-errors';

import { AgenteroUserData } from '../useAgQuery';
import { FeatureFlagsRequest, Flags } from './useFeatureFlagsQuery';

type FeatureFlagsResponseData = {
	flags: Flags;
	valid: boolean;
};

export const fetchFeatureFlags = ({
	userId,
	agencyId,
	token
}: AgenteroUserData & FeatureFlagsRequest): Promise<Flags> => {
	return new Promise<Flags>((resolve, reject) => {
		fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/flags`, {
			method: 'POST',
			body: JSON.stringify({ user: { key: agencyId } }),
			headers: { authorization: `Bearer ${token}` }
		})
			.then(response => handleFetchResponse(response, reject, userId))
			.then((data: FeatureFlagsResponseData) => {
				const parseFlags = Object.keys(data.flags).reduce(
					(acc, key) => ({
						...acc,
						[key]: data.flags[key].value
					}),
					{}
				) as Flags;

				resolve(parseFlags);
			})
			.catch(error => handleFetchCatchError(error, reject, userId));
	});
};
