import { GetAgentsRequest } from '@agentero/grpc-clients/grpc/agent-fe';

export enum AgentsOrderByField {
	FullName = 'fullName',
	Email = 'email',
	Agency = 'agencyName',
	Created = 'createdDate'
}

const map: { [key in AgentsOrderByField]: GetAgentsRequest.OrderBy.OrderByField } = {
	[AgentsOrderByField.FullName]: GetAgentsRequest.OrderBy.OrderByField.ORDER_BY_FIELD_FULL_NAME,
	[AgentsOrderByField.Email]: GetAgentsRequest.OrderBy.OrderByField.ORDER_BY_FIELD_EMAIL,
	[AgentsOrderByField.Agency]: GetAgentsRequest.OrderBy.OrderByField.ORDER_BY_FIELD_AGENCY,
	[AgentsOrderByField.Created]: GetAgentsRequest.OrderBy.OrderByField.ORDER_BY_FIELD_CREATED_AT
};

export const parseAgentOrderByFields = (
	value: AgentsOrderByField
): GetAgentsRequest.OrderBy.OrderByField => map[value];
