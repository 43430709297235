import { ORDER_DIRECTION } from '@agentero/components';
import { AgentsOrderBy, AgentsOrderByField } from '@agentero/models/agent';

export const orderByDirectionQueryStringKey = 'direction';
export const orderByQueryStringKey = 'orderBy';

export const useGetInitialOrder = (queryString: URLSearchParams) => {
	const initialOrderDirection = queryString.get(orderByDirectionQueryStringKey);
	const initialOrderBy = queryString.get(orderByQueryStringKey);

	const existInitialOrderDirection = Object.values(ORDER_DIRECTION).find(
		direction => direction === initialOrderDirection
	);

	const existInitialOrderBy = Object.values(AgentsOrderByField).find(
		field => field === initialOrderBy
	);

	let initialOrder: AgentsOrderBy = {
		direction: ORDER_DIRECTION.DESC,
		field: AgentsOrderByField.Created
	};
	if (existInitialOrderDirection && existInitialOrderBy) {
		initialOrder = {
			direction: initialOrderDirection as ORDER_DIRECTION,
			field: initialOrderBy as AgentsOrderByField
		};
	}

	return initialOrder;
};
