import { FeatureFlagKey } from '@agentero/models/featureflags';

import { useAgentResource } from '../back-ag/useAgentResource';
import { AgenteroUserData, GrpcPortalRequest, fetchAgQuery, useAgQuery } from '../useAgQuery';
import { fetchFeatureFlags } from './fetchFeatureFlags';

export type Flags = { [key in FeatureFlagKey]: boolean };

export type FeatureFlagsRequest = {
	agencyId: string;
};

const resourceKey = 'feature-flag';

const featureFlagsQueryConfig: GrpcPortalRequest<FeatureFlagsRequest, Flags> = {
	request: fetchFeatureFlags,
	key: ({ agencyId, userId }) => [resourceKey, agencyId, userId]
};

export const useFeatureFlagsQuery = () => {
	const {
		data: {
			agency: { id: agencyId }
		}
	} = useAgentResource();

	const { data: flags } = useAgQuery(featureFlagsQueryConfig, { agencyId });

	return flags;
};

const REFETCH_WAITING_TIME = 10 * 60 * 1000;

export const fetchFeatureFlagsQuery = ({
	userId,
	agencyId,
	token
}: FeatureFlagsRequest & AgenteroUserData) => {
	return fetchAgQuery(
		featureFlagsQueryConfig,
		{ agencyId, userId, token },
		{
			staleTime: REFETCH_WAITING_TIME,
			gcTime: REFETCH_WAITING_TIME
		}
	);
};
